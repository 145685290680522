<script setup>
import { onMounted, ref, reactive } from "vue";
import { setPageTitle } from "../utils/pageTitle";
import c3api from "../c3api";
import useSupportTickets from "../hooks/useSupportTickets";
import SignoutWarning from "@/components/user/SignoutWarning.vue"

const orderStats = ref([]);
const accountStats = ref(null);
const chartKey = ref(0);
const loading = ref(false);
const supportTickets = ref(null);
const changelogs = ref(null);
const {
  ticketStatusMap,
} = useSupportTickets();

onMounted(() => {
  loading.value = true;
  setPageTitle("Dashboard");
  getOrderStats();
  getSupportTickets();
  getAccountStats();
  getChangelogs();
  loading.value = false;
});

const chartOptions = reactive({
  chart: {
    toolbar: {
      show: false,
    },
    margin: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    width: "50%",
    id: "daily-orders-chart",
  },
  stroke: {
    colors: ["#3B51EC"],
  },
  tooltip: {
    theme: "dark",
  },
  xaxis: {
    labels: {
      show: false, // hides the x-axis labels
    },
    axisBorder: {
      show: false, // hides the x-axis border
    },
    axisTicks: {
      show: false, // hides the x-axis ticks
    },
  },
  yaxis: {
    labels: {
      show: false, // hides the y-axis labels
    },
    axisBorder: {
      show: false, // hides the y-axis border
    },
    axisTicks: {
      show: false, // hides the y-axis ticks
    },
    title: {
      text: undefined, // removes the y-axis title
    },
  },
  grid: {
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    yaxis: {
      lines: {
        show: false, // This will hide the horizontal grid lines
      },
    },
  },
});

const series = reactive([
  {
    name: "Orders",
    data: [],
    color: "#3B51EC",
  },
]);

const getOrderStats = () => {
  c3api
    .get(`/stats/orders_per_day`)
    .then((response) => {
      orderStats.value = response.data.slice(-10);
      const dates = orderStats.value.map((item) => item.date);
      const counts = orderStats.value.map((item) => item.count);
      chartOptions.xaxis.categories = dates;
      series[0].data = counts;

      // Force chart re-render
      chartKey.value++;
    })
    .catch((error) => {
      console.error(error);
    });
};

const getAccountStats = () => {
  c3api
    .get(`/stats/account_counts`)
    .then((response) => {
      accountStats.value = response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

const getSupportTickets = () => {
  c3api
    .get(`/stats/support_ticket_count_by_status`)
    .then((response) => {
      supportTickets.value = response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

const getChangelogs = () => {
  c3api
    .get(`/changelogs?sort=-id&page=1&per_page=1`)
    .then((response) => {
      changelogs.value = response.data;
    })
    .catch((error) => {
      console.error(error);
    });
};

const generateUrlQueryFilter = (val) => {
  return {
    "filters": JSON.stringify(
      [{ "condition": "eq", "column": "status", "value": val }]
    )
  };
}
</script>

<template>
  <!-- <SignoutWarning /> -->
  <div class="px-4 d-flex mx-2">
    <v-alert color="surface" class="d-flex align-center">
      <span
        class="font-weight-medium text-overline text-indigo-lighten-2">Latest
        Changelog:</span>
      <v-btn v-if="changelogs?.data" flat
        :to="`/changelogs/${changelogs.data[0].id}`"
        :text="changelogs.data[0].title" />
    </v-alert>
  </div>

  <v-row no-gutters class="px-4">
    <v-col align-stretch cols="12" md="4" class="px-2 my-2 py-2">
      <v-card flat class="d-flex flex-column" height="100%">
        <v-card-title>Daily Orders</v-card-title>

        <v-progress-circular color="primary"
          v-if="loading"></v-progress-circular>
        <div class="ml-n3 py-2">
          <apexchart v-if="!loading" width="98%" :key="chartKey" type="line"
            :options="chartOptions" :series="series"></apexchart>
        </div>
      </v-card>
    </v-col>
    <v-col cols="12" md="4" class="px-2 my-2 py-2">
      <v-card flat class="d-flex flex-column" height="100%">
        <v-card-title>Support Tickets</v-card-title>
        <v-progress-circular color="primary"
          v-if="loading"></v-progress-circular>
        <div class="w-100 h-75 mt-8 py-2" v-if="supportTickets">
          <div class="row text-center">
            <router-link tag="span"
              :to="{ path: '/support-tickets', query: generateUrlQueryFilter(ticketStatusMap.open) }"
              class="text-h2 text-decoration-none text-primary">{{
              supportTickets.open }} Open</router-link>
          </div>
          <div class="row text-center mt-4">
            <router-link tag="span"
              :to="{ path: '/support-tickets', query: generateUrlQueryFilter(ticketStatusMap.closed) }"
              class="text-h5 row text-decoration-none text-blue-darken-4">{{
                supportTickets.closed }} Closed</router-link>
          </div>
          <div class="row text-center">
            <router-link tag="span"
              :to="{ path: '/support-tickets', query: generateUrlQueryFilter(ticketStatusMap.onHold) }"
              class="text-h5 row text-decoration-none text-blue-darken-4">{{
                supportTickets.on_hold }} On Hold</router-link>
          </div>
          <div class="row text-center">
            <router-link tag="span"
              :to="{ path: '/support-tickets', query: generateUrlQueryFilter(ticketStatusMap.awaitingResponse) }"
              class="text-h5 row text-decoration-none text-blue-darken-4">{{
                supportTickets.awaiting_response }} Awaiting
              Response</router-link>
          </div>

          <div class="row text-center">
            <router-link tag="span"
              :to="{ path: '/support-tickets', query: generateUrlQueryFilter(ticketStatusMap.inPlanning) }"
              class="text-h5 row text-decoration-none text-blue-darken-4">{{
                supportTickets.in_planning }} In Planning</router-link>
          </div>

          <div class="row text-center">
            <router-link tag="span"
              :to="{ path: '/support-tickets', query: generateUrlQueryFilter(ticketStatusMap.investigating) }"
              class="text-h5 row text-decoration-none text-blue-darken-4">{{
                supportTickets.investigating }} Investigating</router-link>
          </div>

          <div class="row text-center">
            <router-link tag="span"
              :to="{ path: '/support-tickets', query: generateUrlQueryFilter(ticketStatusMap.inProgress) }"
              class="text-h5 row text-decoration-none text-blue-darken-4">{{
                supportTickets.in_progress }} In Progress</router-link>
          </div>

          <div class="row text-center">
            <router-link tag="span"
              :to="{ path: '/support-tickets', query: generateUrlQueryFilter(ticketStatusMap.resolved) }"
              class="text-h5 row text-decoration-none text-blue-darken-4">{{
                supportTickets.resolved }} Resolved</router-link>
          </div>
        </div>
      </v-card>
    </v-col>

    <v-col cols="12" md="4" class="px-2 my-2 py-2">
      <v-card flat class="d-flex flex-column" height="100%">
        <v-card-title>Account Stats</v-card-title>
        <v-progress-circular color="primary"
          v-if="loading"></v-progress-circular>
        <div class="w-100 h-75 mt-8 align-center py-2" v-if="accountStats">
          <div class="row text-center">
            <router-link tag="span" to="/users"
              class="text-h2 text-decoration-none text-primary">{{
                accountStats.total_users }} Users</router-link>
          </div>
          <div class="row text-center mt-4">
            <router-link tag="div" to="/tenants"
              class="text-h5 text-blue-darken-4 text-decoration-none">
              in {{ accountStats.total_tenants }} Tenants
            </router-link>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
